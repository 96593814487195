require('./scss/app.scss');

import $ from 'jquery';
import 'slick-carousel/slick/slick.min';
import 'lazysizes';



document.addEventListener("touchstart", function(){}, true);



$(document).ready(function() {

    $('body img').addClass('lazyload');


    // Slick Sleider Settings
    //
    $('.slick-hero').slick({
        autoplay: true,
        dots: true,
        arrows: false,
        speed: 1600,
        autoplaySpeed: 12000,
        infinite: true,
    });

    $('.slick-career').slick({
        autoplay: true,
        dots: true,
        arrows: false,
        speed: 1600,
        autoplaySpeed: 12000,
        infinite: true,
    });

    $('#content-cc').scroll(function() {
        console.log('content is scrolling');
        let topmenu = $('nav.navbar.navbar-dropdown-fullwidth');
        let header = $('#header');
        let scroll = $('#content-cc').scrollTop();
        if (scroll > header.outerHeight()) {
            topmenu.addClass('fixed');
        } else {
            topmenu.removeClass('fixed');
        }
    });



    $.each($(".c_menuitem"), function() {
        // for tablet
        window.addEventListener('touchstart', function() {
            $(this).on('touchstart', function() {
                $(".c_dropdown_menu", this).slideDown(0);
            });
        });

        $(this).click(function() {
            $(".c_dropdown_menu").slideUp(0);
            $(".c_dropdown_menu", this).slideDown(0);
        });

        // for desktop
        $(this).hover(function() {
            $(".c_dropdown_menu").slideUp(0);
            $(".c_dropdown_menu", this).slideDown(0);
        }, function(){
            $(".c_dropdown_menu").slideUp(0);
        });
    });


    // Anfrageformular Edelstahlmoebel

    let btnAnfrageSenden = $('button#anfrageSenden');
    let elGenCaptcha = $('#massanfertigungCaptchaGen');
    let elCaptcha = $('#massanfertigungCaptcha');

    let captchaGenValue = '';
    captchaGenValue = Math.round(getRandomArbitrary(1000, 9999));

    // console.log(captchaGenValue);

    elGenCaptcha.val(captchaGenValue);

    if(elGenCaptcha.val() != elCaptcha.val()) {
        btnAnfrageSenden.addClass('disabled');
    }

    $('#acceptedRead').click(function() {
        elCaptcha.focusout();
    });

    $('#acceptedCollab').click(function() {
        elCaptcha.focusout();
    });

    elCaptcha.focusout(function() {
        if($(this).val() == captchaGenValue && $('#acceptedRead').prop('checked')==true && $('#acceptedCollab').prop('checked')==true) {
            btnAnfrageSenden.removeClass('disabled');
        } else {
            if(btnAnfrageSenden.hasClass('disabled')) {

            } else {
                btnAnfrageSenden.addClass('disabled');
            }
        }
    });

    function getRandomArbitrary(min, max) {
        return Math.random() * (max - min) + min;
    }



    let selectionCat = $('select#cat');
    let selectionProd = $('select#prod');

    let selectedCanCleanList = $('select#canClean');
    let eqElem1 = '';
    let selectedCanCleanVal = '';


    selectionCat.val('');
    selectionProd.val('');
    selectedCanCleanList.val('');

    selectionCat.change(function(){
        selectionProd.val('');
        selectedCanCleanList.val('');

        let selectionCatVal = selectionCat.children("option:selected").val();
        let eqElem1 = $('.card[data-cat*="' + selectionCatVal + '"]');
        $('.card[data-cat]').fadeOut('hide');
        $('.card[data-cleanItem]').fadeOut('hide');
        if(eqElem1.length > 0) {
            eqElem1.fadeIn('slow');
            $('html, body').stop().animate({
                'scrollTop': selectionCat.offset().top
            }, 900, 'swing')
        }
    });

    selectionProd.change(function(){
        selectionCat.val('');
        selectedCanCleanList.val('');

        let selectionProdVal = selectionProd.children("option:selected").val();
        let eqElem1 = $('.card[data-clean*="' + selectionProdVal + '"]');
        $('.card[data-clean]').fadeOut('hide');
        $('.card[data-cleanItem]').fadeOut('hide');
        if(eqElem1.length > 0) {
            eqElem1.fadeIn('slow');
            $('html, body').stop().animate({
                'scrollTop': selectionProd.offset().top
            }, 900, 'swing')
        }
    });


    selectedCanCleanList.change(function(){
        selectionProd.val('');
        selectionCat.val('');
        selectedCanCleanVal = selectedCanCleanList.children("option:selected").val();
        eqElem1 = $('.card[data-source*="' + selectedCanCleanVal + '"]');

        $('.card[data-cleanItem]').fadeOut('hide');
        $('.card[data-data-clean]').fadeOut('hide');
        $('.card[data-cat]').fadeOut('hide');
        if(eqElem1.length > 0 ) {
            eqElem1.fadeIn('slow');
            $('html, body').stop().animate({
                'scrollTop': selectedCanCleanList.offset().top
            }, 900, 'swing')
        }

    });



});

